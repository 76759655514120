#floor .room {
  background-color: #cbd7ff;
  border: 1px dashed #9cb3ff;
}

.left-column {
  transition: width 0s;
}

.right-column {
  transition: width 0s;
}

@media (min-width: 1200px) {
  .left-column {
    /* Adjust the left column to the minimum width before stacking */
    min-width: 650px;
  }

  .right-column {
    width: 300px;
  }
}

/* When the container becomes too small, adjust the layout */
@media (max-width: 1200px) {
  .left-column {
    /* Adjust the left column to the minimum width before stacking */
    min-width: 400px;
  }

  .right-column {
    width: 300px;
  }
}

/* When both columns reach their minimum sizes, stack them */
@media (max-width: 748px) {
  .left-column, .right-column {
    width: 100%;
    min-width: 0; /* Allow columns to stack */
  }
  .left-column {
    top: 10px;
    position: sticky;
    z-index: 100;
  }
  .left-column::before{
    /* Add a background color to the left column to hide content as it scrolls through the top. */
    content: " ";
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #eee;
    z-index: -1;
  }
  h3 {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  h3 {
    font-size: 11px;
  }
}

@media (max-width: 400px) {
  h3 {
    font-size: 10px;
  }
}

body {
  background-color: #eee;
  font-family: 'Roboto', sans-serif;
}

.ant-layout {
  background-color: #eee !important;
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1050px;
  margin: 0 auto;
}

/** TODO: All of this to move into components */
.floorControls > label {
  font-size: 11px;
  font-weight: 600;
  color: #bbb;
  margin: 10px 0;
  text-transform: uppercase;
  width: 100%;
}
.floorControls {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 13px;
  font-weight: 500;
  color: #222;
  margin-bottom: 10px;
}

label span {
  color: #706E6E;
}
